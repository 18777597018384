import React from 'react'
import '../Introducing/Introducing.css'
import AIimg from '../Introducing/assets/Ai-Ico.svg'
import Instantimg from '../Introducing/assets/Instant-response.svg'
import Multiimg from '../Introducing/assets/Multi-participant-collaboration.svg'
import Expertimg from '../Introducing/assets/Expert-on-demand.svg'
import Manyimg from '../Introducing/assets/Many-possibilities.svg'


const Introducing = () => {
    return (
        <introducing>
            <div className='container fix pt-64'>
                <div className='row m-auto m-rev'>
                    <div className='col-xl-7 col-lg-12 col-md-12 col-xs-12'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12 col-xs-12 left'>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12 col-xs-12 '>
                                        <div className='box'>
                                            <img src={Instantimg} alt='Instant Response'/>
                                            <h6>Instant Response: </h6>
                                            <p>Expedite your decision-making and brainstorming sessions. GemAI ensures rapid and insightful responses, maintaining the smooth flow of your conversation.</p>
                                        </div>
                                    </div> 
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12 col-xs-12'>
                                        <div className='box'>
                                            <img src={Multiimg} alt='Multi-Participant Collaboration'/>
                                            <h6>Multi-Participant Collaboration:</h6>
                                            <p>GemAI effortlessly melds into your email threads, transforming group discussions into richer, smarter, and more efficient interactions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-xs-12 right down'>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12 col-xs-12'>
                                        <div className='box'>
                                            <img src={Expertimg} alt='Expert on Demand'/>
                                            <h6>Expert on Demand:</h6>
                                            <p>With GemAI, you have an expert at your fingertips whenever you need insights or advice. <span className='s-d'><br />&nbsp;</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12 col-xs-12'>
                                        <div className='box'>
                                            <img src={Manyimg} alt='One Thread, Many Possibilities'/>
                                            <h6>One Thread, Many Possibilities:</h6>
                                            <p>No more switching between emails or apps. Centralize all your discussions and insights within a single thread for utmost clarity and coherence</p>
                                        </div>
                                    </div>
                                </div>                                    
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-4 col-lg-12 col-md-12 col-xs-12 m-auto ctm'>
                        <img className='mb-3' src={AIimg} alt='AI'/>
                        <h2>Introducing <span>GemAI</span> Convo by <span>BlueMail</span></h2>
                        <p className='keepM'>Where the power of ChatGPT enhances your group communications.</p>
                    </div>
                </div>
            </div>
        </introducing>
    )
}

export default Introducing
import React from 'react';
import './EmailConversation.css';
import { redirectToStore } from '../../../utils/redirect';
import ArrowDownimg from '../EmailConversation/assets/arrow-down.svg';
import Rocketimg from '../EmailConversation/assets/rocket.svg';
import EmailConversationimg from '../EmailConversation/assets/Email-conversation.webp';


const EmailConversation = () => {
    return (
        <emailconversation>
            <div className='container fix pt-130 pb-130'>
                <div className='row m-auto'>
                    <div className='col-lg-5 offset-col-lg-1 col-md-6 col-xs-12 m-auto'>
                    <img className='mb-3' src={Rocketimg} alt='Rocket'/>
                        <h2>Experience the Future of <span>Email</span> Convos:</h2>
                        <p>Become a part of the revolution. Elevate your email threads with GemAI Convo. Download BlueMail now and try it out for yourself!</p>
                        <a
                          href="#"
                          activeClassName="active"
                          onClick={e => {
                            e.preventDefault()
                            redirectToStore(e)
                          }}
                          className={"default-btn mt-1"}
                        >
                          Try it yourself today! <span></span> <img src={ArrowDownimg} alt="Arrow down"/> 
                        </a>
                    </div>
                    <div className='col-lg-6 col-md-6 col-xs-12 m-auto'>
                        <img src={EmailConversationimg} alt='Email Convo'/>                      
                    </div>
                </div>
            </div>
        </emailconversation>
    )
}

export default EmailConversation
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import './Header.css';
import HeaderImgDesktop from '../Header/assets/header-d.webp';
import HeaderImgLatop from '../Header/assets/header-l.webp';
import HeaderImgTablet from '../Header/assets/header-t.webp';
import HeaderImgMobile from '../Header/assets/header-m.webp';
import HeaderImgSmallMobile from '../Header/assets/header-ms.webp';
import ArrowLeftImg from '../Header/assets/arrow-left.png';

const Header = () => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    if (typeof window !== 'undefined') {
      // Only attach event listener when running in the browser
      setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  let headerImg;
  if (windowWidth >= 1601) {
    headerImg = HeaderImgDesktop;
  } else if (windowWidth >= 1199) {
    headerImg = HeaderImgLatop;
  } else if (windowWidth >= 768) {
    headerImg = HeaderImgTablet;
  } else if (windowWidth >= 561) {
    headerImg = HeaderImgMobile
  } else {
    headerImg = HeaderImgSmallMobile;
  }

    return (
        <header>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-xs-12 d-1'>
                        <img src={headerImg} alt='Header' />
                    </div>
                    <div className='col-lg-6 col-md-6 col-xs-12 d-2'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-xs-12'>
                                <h1>GemAI <br className='br-s'/> Convo</h1>
                                <p>Power Your Conversations <br className='br-s'/> with AI!</p>
                                <Link to='/download' className='default-btn-white m-none'>Try now</Link>
                            </div>
                        </div>
                        <div className='row return'>
                            <div className='col-lg-12 col-md-12 col-xs-12'>
                                <Link to='/'><img src={ArrowLeftImg} alt='Go to bluemail.me'/></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
import React from 'react'
import '../GemAI/GemAI.css'
import { redirectToStore } from '../../../utils/redirect'
import GemAIimg from '../GemAI/assets/GemAI.svg'
import ArrowDownimg from '../GemAI/assets/arrow-down.svg'
import Bluemailx from '../GemAI/assets/bluemailx.webp'


const GemAI = () => {
    return (
        <gemai>
            <div className='container fix pt-64'>
                <div className='row m-auto'>
                    <div className='col-lg-6 col-md-12 col-xs-12 m-auto'>
                        <img className='mb-3' src={GemAIimg} alt='GemAI' />
                        <h2>BlueMail <span>GemAI</span></h2>
                        <p>BlueMail's GemAI integration allows seamless communication with the AI, promoting Shared Perspectives for unified conversation views. GemAI offers smart recommendations, improving productivity by reducing response times and expediting projects. It prioritizes user privacy, adhering to strict data privacy standards for confidential conversations.</p>
                        <a
                          href="#"
                          activeClassName="active"
                          onClick={e => {
                            e.preventDefault()
                            redirectToStore(e)
                          }}
                          className={"default-btn mt-1"}
                        >
                          Download Bluemail <span></span> <img src={ArrowDownimg} alt="Arrow down"/> 
                        </a>
                    </div>
                    <div className='col-lg-6 col-md-12 col-xs-12 m-auto'>
                        <img src={Bluemailx} type="BluemailX"/>                   
                    </div>
                </div>
            </div>
        </gemai>
    )
}

export default GemAI
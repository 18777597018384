import React from 'react'
import '../HowItWorks/HowItWorks.css'
import Menimg from '../HowItWorks/assets/Men.svg'
import Oneimg from '../HowItWorks/assets/1.svg'
import Twoimg from '../HowItWorks/assets/2.svg'
import Threeimg from '../HowItWorks/assets/3.svg'
import Fourimg from '../HowItWorks/assets/4.svg'
import how1img from '../HowItWorks/assets/how-1.webp'
import how2img from '../HowItWorks/assets/how-2.webp'
import how3img from '../HowItWorks/assets/how-3.webp'
import how4img from '../HowItWorks/assets/how-4.webp'

const HowItWorks = () => {
    return (
        <howitworks>
            <div className='container pt-64'>
                <div className='box-container'>
                    <div className='row m-auto text-center'>
                        <div className='col-lg-12 col-md-12 col-xs-12 m-auto'>
                            <div className='col-lg-12 col-md-12 col-xs-12'>
                                <img className='mb-3 bkgIco' src={Menimg} alt='Men' />
                                <h2>How It Works?</h2>
                            </div>
                        </div>
                    </div>      
                    <div className='row mt-3'>
                        <div className='col-xl-9 col-lg-12 col-md-12 col-xs-12 d-flex justify-content-center m-auto'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-xs-12 mt-3 pr'>
                                    <div className='box'>
                                        <img className='mb-1' src={Oneimg} alt='One' />
                                        <p>Initiate a Conversation</p>
                                        <p className='small'>Press on GemAI within your email compose.</p>
                                        <img className='mt-3'src={how1img} alt='How 1' />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-xs-12 mt-3 pl'>
                                    <div className='box'>
                                        <img className='mb-1' src={Twoimg} alt='Two' />
                                        <p>Add Members</p>
                                        <p className='small'>Add the relevant people for the conversation.</p>
                                        <img className='mt-3'src={how2img} alt='How 2' />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-xs-12 mt-3 pr'>
                                    <div className='box'>
                                        <img className='mb-1' src={Threeimg} alt='Three' />
                                        <p>Engage with GemAI Convo</p>
                                        <p className='small'>Pose questions, request clarifications, or brainstorm. GemAI interacts with all participants, enhancing the quality of your discussions.</p>
                                        <img className='mt-3' src={how3img} alt='How 3' />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-xs-12 mt-3 pl'>
                                    <div className='box'>
                                        <img className='mb-1' src={Fourimg} alt='Four' />
                                        <p>Conclude & Reflect</p>
                                        <p className='small'>After your discussions, review the entire thread and take note of all the invaluable contributions from GemAI.<span className='s-d'><br />&nbsp;</span></p>
                                        <img className='mt-3' src={how4img} alt='How 4' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>          
                </div>
            </div>
        </howitworks>
    )
}

export default HowItWorks
import React, { useState, useEffect } from 'react';
import './Testimonials.css';
import leftArrowimg from '../Testimonials/assets/arrow-left.svg';
import rightArrowimg from '../Testimonials/assets/arrow-right.svg';
import Quoteimg from '../Testimonials/assets/quote-left.svg';
import Testimimg1 from '../Testimonials/assets/Testim1.webp';
import Testimimg2 from '../Testimonials/assets/Testim2.webp';

const Testimonials = () => {
  const testimonialsData = [
    {
      image: Testimimg1,
      text: '"I had my reservations initially, but GemAI in our email conversations feels like having an expert for every subject. Truly transformative!"',
      name: 'Elena K',
      position: 'Marketing Specialist',
    },
    {
      image: Testimimg2,
      text: '“Our team meetings are now super-efficient with GemAI Convo. We gain instant insights which greatly speeds up our decision-making process”',
      name: 'Bob R',
      position: 'Director of Strategic Initiatives.',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const showLeftArrow = currentIndex > 0;
  const showRightArrow = currentIndex < testimonialsData.length - (isMobile ? 1 : 2);

  const handleLeftArrowClick = () => {
    if (showLeftArrow) {
      setCurrentIndex(currentIndex - (isMobile ? 1 : 2));
    }
  };

  const handleRightArrowClick = () => {
    if (showRightArrow) {
      setCurrentIndex(currentIndex + (isMobile ? 1 : 2));
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 991);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 991);
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return (
    <testimonials>
      <div className='container pt-64'>
        <div className='row m-rev'>
          <div className={`col-lg-8 col-md-12 col-xs-12 m-auto ${isMobile ? 'col-12' : ''}`}>
            <div className='row'>
              {testimonialsData.slice(currentIndex, currentIndex + (isMobile ? 1 : 2)).map((testimonial, index) => (
                <div
                  className={`col-lg-${isMobile ? 12 : 6} col-md-${isMobile ? 12 : 6} col-xs-12 m-auto`}
                  key={index}
                >
                  <div className='box'>
                    <p>{testimonial.text}</p>
                    <div className='box-content'>
                      <div className='box-image'>
                        <img src={testimonial.image} alt={`Testimonial ${index + 1}`} />
                      </div>
                      <div>
                        <p>{testimonial.name}</p>
                        <p className='small'>{testimonial.position}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='col-lg-4 col-md-12 col-xs-12 m-auto'>
            <div className='ml-4'>
              <img className='mb-3 quote' src={Quoteimg} alt='Quote' />
              <h2 className='mb-4'>User <span>Testimonials</span></h2>
              {testimonialsData.length <= 2 && isMobile && <div className='arrows-m'>
                <img
                  className={`arrow mr-2 ${showLeftArrow ? '' : 'disabled'}`}
                  src={leftArrowimg}
                  alt='Left Arrow'
                  onClick={handleLeftArrowClick}
                />
                <img
                  className={`arrow ${showRightArrow ? '' : 'disabled'}`}
                  src={rightArrowimg}
                  alt='Right Arrow'
                  onClick={handleRightArrowClick}
                />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </testimonials>
  );
};

export default Testimonials;
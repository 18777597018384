import React from "react"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Layout from "../../components/App/Layout"
import SEO from '../../components/App/SEO'
import { graphql } from 'gatsby'
import Header from "../../components/GemAI/Header/Header"
import Introducing from '../../components/GemAI/Introducing/Introducing'
import GemAI from "../../components/GemAI/GemAI/GemAI"
import HowItWorks from "../../components/GemAI/HowItWorks/HowItWorks"
import Testimonials from "../../components/GemAI/Testimonials/Testimonials"
import EmailConversation from "../../components/GemAI/EmailConversation/EmailConversation"
import '../../components/GemAI/assets/css/General.css';
import '../../components/GemAI/assets/css/GeneralResponsive.css';


const GemAIConvos = ({data}) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="BlueMail - The Best Email App for Windows, Mac, Linux, Android and iOS"
        postDescription="BlueMail is a modern, mobile and desktop, powerful Email management tool with a sleek design, unified inbox and support for all your accounts: IMAP, Exchange,POP3."
        postImage="/img/OG/og_image_Home.png"
        postURL=""
        postSEO
      />
      
      <div id="gemAIMain">
      <Navbar />
      <Header />
      <Introducing />
      <GemAI />
      <HowItWorks />
      <Testimonials />
      <EmailConversation />
      </div>
      <Footer />
    </Layout>
  )
}

export default GemAIConvos

export const query = graphql`
query GemAIConvosPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
